<template>
  <gmaps-popup
    v-if="visible"
    :position="position"
    background="#fff"
    :height="40"
    :width="100"
    :options="{ shouldFocus: false }"
    :key="infocard.id"
  >
    <div class="wrap">
      <confirm-modal ref="confirmSameDateOrderModal" />
      <b-row>
        <b-col cols="5">
          <img
            v-if="infocard.coverPath"
            :src="`${apiBaseUrl()}/images/${infocard.coverPath}`"
          />
          <img v-else src="@/assets/images/container-no-image.jpg" />
        </b-col>
        <b-col cols="7" class="pl-0">
          <span class="font-weight-bold title"
            >{{ infocard.name }}
            <small>{{ infocard.refId }}</small>
          </span>
          <span
            ><small> {{ T(`Web.Generic.Infocards.Types.${infocard.type.replace('InfoCard', '')}`) }}</small></span
          >
          <template v-if="infocard.type == 'ContainerInfoCard'">
            <span>
              <small v-if="infocard.wasteFractionName != null"
                >{{ infocard.wasteFractionName }},</small
              >
              <small v-if="infocard.size > 0">
                {{ infocard.size | toCurrency }}
                {{ getContainerSizeUnit(infocard.sizeUnit).text }}
              </small>
            </span>
            <span
              ><feather-icon icon="TruckIcon" />
              {{ infocard.collectorName }}</span
            >
          </template>
          <b-button
            :variant="
              orderList.includes(infocard) ? 'primary' : 'flat-secondary'
            "
            class="float-right"
            size="sm"
            v-if="orderableMode && infocard.type == 'ContainerInfoCard'"
            @click="toggleSelectInfocard(infocard)"
          >
            <template v-if="orderList.includes(infocard)"
              ><feather-icon icon="CheckIcon"
            /></template>
            <template v-else>{{ T("Web.Generic.Select") }}</template>
          </b-button>
          <b-button
            v-else
            size="sm"
            variant="flat-secondary"
            class="float-right"
            @click="$emit('edit-infocard-clicked', infocard.id)"
          >
            {{ T("Web.Generic.ShowEdit") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </gmaps-popup>
</template>
<script>
import { gmapsPopup } from "x5-gmaps";
import { BRow, BCol, BBadge, BButton } from "bootstrap-vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  components: {
    gmapsPopup,
    BRow,
    BCol,
    BBadge,
    BButton,
    ConfirmModal,
  },
  props: {
    infocard: {
      type: Object,
      required: true,
    },
    visible: {
      required: false,
      default: false,
    },
    orderableMode: {
      required: false,
      default: false,
    },
    orderList: {
      required: false,
      default: [],
    },
  },
  computed: {
    position() {
      return {
        lat: Number.parseFloat(this.infocard.latitude),
        lng: Number.parseFloat(this.infocard.longitude),
      };
    },
  },
  methods: {
    async openEditInfocardModal(infocardId) {
      await this.$refs.createOrUpdateInfocardModal.openEdit(infocardId);
    },
    async toggleSelectInfocard(infocard) {
      const existingIndex = this.orderList.indexOf(infocard);
      if (existingIndex > -1) this.orderList.splice(existingIndex, 1);
      else {
        const today = new Date();
        const lastOrderDate = new Date(infocard.lastOrderDate);
        if (
          today.getFullYear() === lastOrderDate.getFullYear() &&
          today.getMonth() === lastOrderDate.getMonth() &&
          today.getDate() === lastOrderDate.getDate() &&
          !(await this.$refs.confirmSameDateOrderModal.confirm(
            this.T("Web.Generic.Ordering.OrderSameDateConfirmText")
          ))
        ) {
          return;
        }
        this.orderList.push(infocard);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  height: auto;
  width: 300px;
  overflow: hidden;
  img {
    height: 100px;
    width: 100%;
  }
  span {
    display: block;
    margin: 5px 0;
  }
  .title {
    display: block;
    font-size: 15px;
    border-bottom: 2px solid #f4f4fb;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>