<template>
  <page-loader :loading="infocardsIsLoading">
    <template v-if="hasNoLocations">
      <b-card>
        <div v-if="admin" class="d-flex justify-content-center flex-column">
          <h3 class="text-center text-muted">
            {{ T("Web.Generic.Locations.NoLocations", "No locations") }}
          </h3>
          <h6 class="text-center text-muted">
            {{ T("Web.InfocardsPage.CreateLocationInfo", "You need to create a location in order to create an infocard.") }}
          </h6>
        </div>
        <div v-else class="d-flex justify-content-center flex-column">
          <h3 class="text-center text-muted">
            {{ T("Web.Generic.Locations.NotAccessToLocation") }}
          </h3>
        </div>
      </b-card>
    </template>
    <infocards-list :infocards="infocards" :filters="filters" v-else />
  </page-loader>
</template>

<script>
import PageLoader from "@/app/components/layout/PageLoader.vue";
import InfocardsList from "./components/InfocardListing/InfocardsList.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { InfocardsList, PageLoader },
  async created() {
    await this.fetchInfocards({
      params: { locationId: this.currentLocation?.id },
    });
  },
  data() {
    return {
      filters: {
        locationId: this.currentLocation?.id,
        type: ["AccountInfoCard", "ContainerInfoCard"],
        fractions: [],
        fractionsOptions: [],
        ewcCodes: [],
        ewcCodesOption: [],
        categories: [],
        categoriesOptions: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      infocardsIsLoading: "infocards/isFetchingList",
      allInfocards: "infocards/list",
      currentLocation: "appUser/getCurrentLocationScope",
      locations: "locations/list",
    }),
    hasNoLocations() {
      return this.locations.lenght == 0;
    },
    infocards() {
      return this.allInfocards.sort((a, b) => (a.name > b.name) * 2 - 1);
    },
  },
  methods: {
    ...mapActions({ fetchInfocards: "infocards/fetchList" }),
  },
  watch: {
    async currentLocation(location) {
      await this.fetchInfocards({ params: { locationId: location?.id } });
      this.filters.locationId = location != null ? location.id : null;
    },
    infocards() {
      this.filters.fractions = this.allInfocards.reduce((pre, cur) => {
        let val =
          cur.wasteFractionName == null ? "null" : cur.wasteFractionName;
        if (!pre.includes(val)) pre.push(val);
        return pre;
      }, []);
      this.filters.fractionsOptions = this.filters.fractions;
      this.filters.ewcCodes = this.allInfocards.reduce((pre, cur) => {
        let val = cur.ewcCode == null ? "null" : cur.ewcCode;

        if (!pre.includes(val)) pre.push(val);
        return pre;
      }, []);
      this.filters.ewcCodesOption = this.filters.ewcCodes;
      this.filters.categories = this.allInfocards.reduce((pre, cur) => {
        let val = cur.wasteCategory == null ? "null" : cur.wasteCategory;

        if (!pre.includes(val)) pre.push(val);
        return pre;
      }, []);
      this.filters.categoriesOptions = this.filters.categories;
    },
  },
};
</script>