var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"infocardWrap",staticClass:"infocards-wrap",style:({
    height: (_vm.wrapHeight + "px"),
  })},[_c('infocards-list-view-controls'),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showInner)?_c('b-container',{staticClass:"inner p-0"},[_c('div',{staticClass:"maps-container",class:{
          'h-100': _vm.layoutMode == 'Map',
          'd-none': _vm.layoutMode == 'List',
        }},[_c('b-card',{staticClass:"h-100 w-100 overflow-hidden",attrs:{"no-body":""}},[_c('infocards-location-map',{attrs:{"orderable-mode":_vm.orderableMode,"lng":_vm.mapLng,"lat":_vm.mapLat,"infocards":_vm.filteredInfocards,"highlighted-infocard":_vm.highlightedInfocard,"order-list":_vm.orderList},on:{"infocard-marker-clicked":function (infocard) { return (_vm.highlightedInfocard = infocard); }}})],1)],1),_c('div',{staticClass:"infocards-table-container",class:{
          'h-100': _vm.layoutMode == 'List',
          'd-none': _vm.layoutMode == 'Map',
        }},[_c('b-card',{staticClass:"h-100 w-100 overflow-hidden",attrs:{"no-body":""}},[_c('infocards-table',{attrs:{"orderable-mode":_vm.orderableMode,"filters":_vm.filters,"infocards":_vm.filteredInfocards,"order-list":_vm.orderList},on:{"infocard-mouse-over":function (infocard) { return (_vm.highlightedInfocard = infocard); },"infocard-mouse-leave":function($event){_vm.highlightedInfocard = null},"infocard-orders-show-clicked":function (event) { return _vm.$emit('infocard-orders-show-clicked', event); }}})],1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }