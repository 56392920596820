<template>
  <div :class="{active : activeOrderItem == itemKey}" @click="$emit('order-clicked', itemKey)">
    <slot></slot> <feather-icon :icon="(orderDirection) ? 'ArrowDownIcon' : 'ArrowUpIcon'" v-if="activeOrderItem == itemKey"/> 
  </div>
</template>

<script>
export default {
  props: {
    itemKey: {
      required: true,
    },
    activeOrderItem: {
      required: true,
    },
    orderDirection: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #407e3d !important;
}
div:hover{
    cursor: pointer;
}
</style>