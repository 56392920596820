<template>
  <span>{{ textDisplay }}</span>
</template>

<script>
export default {
  props: {
    dateTime: {
      required: true,
    },
  },

  data() {
    return {
      timeSinceText: "",
      timer: null,
    };
  },

  created() {
    this.updateDisplay();
  },

  methods: {
    updateDisplay() {
      if (this.timer != null) clearTimeout(this.timer);
      if(this.dateTime == null) return this.timeSinceText = this.T('Web.Generic.TimeSince.Never', 'Never');
      let secondsSince = Math.floor(
        (new Date() - new Date(this.dateTime)) / 1000
      );
      this.timeSinceText = this.getTimeSinceFromSeconds(secondsSince);
      this.timer = this.getTimeoutFromSeconds(secondsSince);
    },
    getTimeoutFromSeconds(seconds) {
      if (seconds < this.minutesToSeconds(61))
        return setTimeout(
          () => this.updateDisplay(),
          this.minutesToSeconds(1) * 1000
        );
      else if (seconds < this.hoursToSeconds(25))
        return setTimeout(
          () => this.updateDisplay(),
          this.hoursToSeconds(1) * 1000
        );
        else return null
    },
    getTimeSinceFromSeconds(seconds) {
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `${Math.floor(interval)}  ${this.T('Web.Generic.TimeSince.YearsAgo', 'Year(s) ago')}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.T('Web.Generic.TimeSince.MonthsAgo', 'Month(s) ago')}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.T('Web.Generic.TimeSince.DaysAgo','Day(s) ago')}`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.T('Web.Generic.TimeSince.HoursAgo', 'Hour(s) ago')}`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${this.T('Web.Generic.TimeSince.MinutesAgo', 'Minute(s) ago')}`;
      }
      return this.T('Web.Generic.TimeSince.SecondsAgo', 'Second(s) ago');
    },
    minutesToSeconds(minutes) {
      return minutes * 60;
    },
    hoursToSeconds(hours) {
      return this.minutesToSeconds(hours * 60);
    },
  },

  computed: {
    textDisplay() {
      return this.timeSinceText;
    },
  },

  watch: {
    dateTime() {
      this.updateDisplay();
    },
  },
};
</script>