<template>
  <div
    ref="infocardWrap"
    class="infocards-wrap"
    :style="{
      height: `${wrapHeight}px`,
    }"
  >
    <infocards-list-view-controls />
    <transition name="slide-fade">
      <b-container class="inner p-0" v-if="showInner">
        <div
          class="maps-container"
          :class="{
            'h-100': layoutMode == 'Map',
            'd-none': layoutMode == 'List',
          }"
        >
          <b-card class="h-100 w-100 overflow-hidden" no-body>
            <infocards-location-map
              :orderable-mode="orderableMode"
              :lng="mapLng"
              :lat="mapLat"
              :infocards="filteredInfocards"
              :highlighted-infocard="highlightedInfocard"
              :order-list="orderList"
              @infocard-marker-clicked="
                (infocard) => (highlightedInfocard = infocard)
              "
            />
          </b-card>
        </div>
        <div
          class="infocards-table-container"
          :class="{
            'h-100': layoutMode == 'List',
            'd-none': layoutMode == 'Map',
          }"
        >
          <b-card class="h-100 w-100 overflow-hidden" no-body>
            <infocards-table
              :orderable-mode="orderableMode"
              :filters="filters"
              :infocards="filteredInfocards"
              :order-list="orderList"
              @infocard-mouse-over="
                (infocard) => (highlightedInfocard = infocard)
              "
              @infocard-mouse-leave="highlightedInfocard = null"
              @infocard-orders-show-clicked="
                (event) => $emit('infocard-orders-show-clicked', event)
              "
            />
          </b-card>
        </div>
      </b-container>
    </transition>
  </div>
</template>

<script>
import { BRow, BContainer, BCard } from "bootstrap-vue";
import InfocardsListViewControls from "./InfocardsListViewControls.vue";
import { mapGetters } from "vuex";
import InfocardsLocationMap from "./maps/InfocardsLocationMap.vue";
import InfocardsTable from "./InfocardsTable.vue";
export default {
  components: {
    BContainer,
    BCard,
    BRow,
    InfocardsListViewControls,
    InfocardsLocationMap,
    InfocardsTable,
  },
  props: {
    orderList: {
      required: false,
      default: () => [],
    },
    orderableMode: {
      required: false,
      default: false,
    },
    infocards: {
      required: false,
      default: () => [],
    },
    filters: {
      required: false,
      default: () => ({
        type: [],
        fractions: [],
        fractionsOptions: [],
        locationId: null,
        ewcCodes: [],
        ewcCodesOptions: [],
        categories: [],
        categoriesOptions: [],
      }),
    },
  },
  data() {
    return {
      wrapHeight: 0,
      showInner: true,
      highlightedInfocard: null,
    };
  },
  mounted() {
    this.updateVhHeight();
    window.addEventListener("resize", () => this.updateVhHeight());
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    filteredInfocards() {
      let filteredList = this.infocards.filter((i) =>
        this.filters.type.includes(i.type)
      );
      if (this.filters.locationId != null)
        filteredList = filteredList.filter(
          (i) => i.locationId == this.filters.locationId
        );

      filteredList = filteredList.filter((i) => {
        return i.wasteFractionName == null || i.wasteFractionName == undefined
          ? this.filters.fractions.includes("null")
          : this.filters.fractions.includes(i.wasteFractionName);
      });
      filteredList = filteredList.filter((i) => {
        return i.wasteCategory == null || i.wasteCategory == undefined
          ? this.filters.categories.includes("null")
          : this.filters.categories.includes(i.wasteCategory);
      });
      filteredList = filteredList.filter((i) => {
        return i.ewcCode == null || i.ewcCode == undefined
          ? this.filters.ewcCodes.includes("null")
          : this.filters.ewcCodes.includes(i.ewcCode);
      });
      return filteredList;
    },
    layoutMode: {
      get() {
        return this.getUserSetting("infocards.list.pageLayout", "List");
      },
      async set(value) {
        await this.setUserSettingAsync("infocards.list.pageLayout", value);
      },
    },
    currentLocation() {
      return this.locations.find((l) => l.id == this.filters.locationId);
    },
    mapLng() {
      return this.currentLocation?.longitude;
    },
    mapLat() {
      return this.currentLocation?.latitude;
    },
  },
  methods: {
    updateVhHeight() {
      const positionFromTop =
        this.$refs.infocardWrap.getBoundingClientRect().top;
      this.wrapHeight = window.innerHeight - positionFromTop;
    },
  },
  watch: {
    layoutMode() {
      this.showInner = false;
      const self = this;
      setTimeout(function () {
        self.showInner = true;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.infocards-wrap {
  .inner {
    height: 100%;
    .maps-container {
      height: 40%;
      padding-bottom: 20px;
    }
    .infocards-table-container {
      height: 60%;
      padding-bottom: 20px;
    }
  }
}
</style>