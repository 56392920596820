<template>
  <b-card class="view-mode-controls" no-body>
    <ul>
      <li
        :class="{ active: layoutMode == 'List+Map' }"
        @click="layoutMode = 'List+Map'"
      >
        <img src="~@/assets/images/maplist_icon.svg" />
      </li>
      <li
        :class="{ active: layoutMode == 'List' }"
        @click="layoutMode = 'List'"
      >
        <img src="~@/assets/images/list_icon.svg" />
      </li>
      <li :class="{ active: layoutMode == 'Map' }" @click="layoutMode = 'Map'">
        <img src="~@/assets/images/map_icon.svg" />
      </li>
    </ul>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
  computed: {
    layoutMode: {
      get() {
        return this.getUserSetting("infocards.list.pageLayout", "List");
      },
      async set(value) {
        await this.setUserSettingAsync("infocards.list.pageLayout", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.view-mode-controls {
  top: 78px;
  right: 30px;
  position: absolute;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      padding: 10px;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      list-style: none;
      &:hover,
      &.active {
        background-color: #f4f4fb;
      }
    }
  }

  @media only screen and (max-width: 648px) {
    right: 15px;
    top: 82px;
  }
}
</style>