<template>
  <b-dropdown
    :text="T('Web.Generic.Filters', 'Filters')"
    size="sm"
    variant="flat-secondary"
    v-if="content"
  >
    <template #button-content>
      <feather-icon icon="FilterIcon" />
    </template>
    <b-dropdown-form class="inner">
      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-label">
          {{ T("Web.Generic.Infocards.Type") }}:
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllTypes">{{
            T("Web.Generic.SelectDeselectAll")
          }}</a></small
        >
      </div>
      <b-form-checkbox-group
        id="checkbox-group-infocard-type"
        v-model="content.type"
        @change="$emit('input', content)"
      >
        <b-form-checkbox value="AccountInfoCard">{{
          T("Web.Generic.Infocards.Types.Account")
        }}</b-form-checkbox>
        <b-form-checkbox value="ContainerInfoCard">{{
          T("Web.Generic.Infocards.Types.Container")
        }}</b-form-checkbox>
      </b-form-checkbox-group>
      <br />
      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-0-label">
          {{ T("Web.Generic.Infocards.WasteCategory") }}:
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllCategories">{{
            T("Web.Generic.SelectDeselectAll")
          }}</a></small
        >
      </div>

      <b-form-checkbox-group
        id="checkbox-group-infocard-categories"
        v-model="content.categories"
        v-if="categoriesOptions.length > 0"
      >
        <b-form-checkbox
          class="w-100"
          v-for="(category, index) in categoriesOptions"
          :key="index"
          :value="category"
          >{{
            category != "null"
              ? T(`Web.Generic.WasteCategories.${category}`)
              : T("Web.Generic.WasteCategories.None")
          }}</b-form-checkbox
        >
      </b-form-checkbox-group>
      <br />
      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-1-label">
          {{ T("Web.Generic.Infocards.Fraction") }}:
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllFractions">{{
            T("Web.Generic.SelectDeselectAll")
          }}</a></small
        >
      </div>

      <b-form-checkbox-group
        id="checkbox-group-infocard-fraction"
        v-model="content.fractions"
        v-if="fractionsOptions.length > 0"
      >
        <b-form-checkbox
          class="w-100"
          v-for="(fraction, index) in fractionsOptions"
          :key="index"
          :value="fraction"
          >{{
            fraction != "null"
              ? fraction
              : T("Web.Generic.Infocards.WithoutFraction", "Without fraction")
          }}</b-form-checkbox
        >
      </b-form-checkbox-group>
      <br />
      <div class="d-flex justify-content-between align-items-end">
        <b-dropdown-header id="dropdown-header-2-label">
          {{ T("Web.Generic.Infocards.EWCCode") }}:
        </b-dropdown-header>
        <small
          ><a href="#" @click.prevent="selectDeselectAllEWCs">{{
            T("Web.Generic.SelectDeselectAll")
          }}</a></small
        >
      </div>

      <b-form-checkbox-group
        id="checkbox-group-infocard-ewc"
        v-model="content.ewcCodes"
        v-if="ewcOptions.length > 0"
      >
        <b-form-checkbox
          class="w-100"
          v-for="(ewc, index) in ewcOptions"
          :key="index"
          :value="ewc"
          >{{
            ewc != "null" ? ewc : T("Web.Generic.Infocards.WithoutEWCCode", "WithoutEWCCode")
          }}</b-form-checkbox
        >
      </b-form-checkbox-group>
      <div class="d-flex justify-content-center mt-1">
        <small
          ><a href="#" @click.prevent="resetFilters">{{
            T("Web.Generic.Reset", "Reset")
          }}</a></small
        >
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownForm,
  BDropdownHeader,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
export default {
  props: {
    value: {
      required: true,
    },
    fractionsOptions: {
      required: true,
    },
    ewcOptions: {
      required: true,
    },
    categoriesOptions: {
      required: true,
    },
  },
  components: {
    BDropdown,
    BDropdownForm,
    BDropdownHeader,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    selectDeselectAllTypes() {
      if (this.content.type.length != 2) {
        this.content.type = ["AccountInfoCard", "ContainerInfoCard"];
      } else {
        this.content.type = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllCategories() {
      if (this.content.categories.length != this.categoriesOptions.length) {
        this.content.categories = this.categoriesOptions;
      } else {
        this.content.categories = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllFractions() {
      if (this.content.fractions.length != this.fractionsOptions.length) {
        this.content.fractions = this.fractionsOptions;
      } else {
        this.content.fractions = [];
      }
      this.$emit("input", this.content);
    },
    selectDeselectAllEWCs() {
      if (this.content.ewcCodes.length != this.ewcOptions.length) {
        this.content.ewcCodes = this.ewcOptions;
      } else {
        this.content.ewcCodes = [];
      }
      this.$emit("input", this.content);
    },
    resetFilters() {
      this.content.type = ["AccountInfoCard", "ContainerInfoCard"];
      this.content.categories = this.categoriesOptions;
      this.content.fractions = this.fractionsOptions;
      this.content.ewcCodes = this.ewcOptions;
      this.$emit("input", this.content);
    },
  },
};
</script>

<style scoped>
.dropdown-header {
  padding: 0.5rem 0;
}
.inner {
  max-height: 400px;
  overflow: auto;
}
.b-dropdown-form {
  min-width: 240px;
}
</style>