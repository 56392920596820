<template>
  <div class="wrap" :class="{ grid: viewMode == 'GRID' }">
    <confirm-modal ref="confirmDateModal" />
    <section class="infocard-table">
      <create-or-update-infocard-modal ref="createOrUpdateInfocardModal" />
      <show-infocard-modal ref="showInfocardModal" />
      <header>
        <!-- List mode selector -->
        <div
          class="list-mode-selector"
          @click="
            viewMode == 'LIST' ? (viewMode = 'GRID') : (viewMode = 'LIST')
          "
        >
          <feather-icon
            v-if="viewMode == 'GRID'"
            icon="AlignJustifyIcon"
            :class="{ 'text-primary': viewMode == 'LIST' }"
            size="18"
            v-b-tooltip.hover.top="
              T('Web.InfocardsPage.Table.ShowListLayout', 'Show list layout')
            "
          />
          <feather-icon
            v-else
            icon="GridIcon"
            :class="{ 'text-primary': viewMode == 'GRID' }"
            size="18"
            v-b-tooltip.hover.top="
              T('Web.InfocardsPage.Table.ShowGridLayout', 'Show grid layout')
            "
          />
        </div>
        <!-- Name  -->
        <div>
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="name"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.InfocardName", "Infocard name") }}
          </order-table-icon>
        </div>
        <!-- Last orderdate -->
        <div v-if="orderableMode">
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="lastOrderDate"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.LastCollected", "LastCollected") }}
          </order-table-icon>
        </div>
        <!-- Collector -->
        <div class="ollector-col">
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="collectorName"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.Collector", "Collector") }}
          </order-table-icon>
        </div>
        <!-- Type  -->
        <div v-if="!orderableMode">
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="type"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.Type", "Type") }}
          </order-table-icon>
        </div>
        <!-- Fraction  -->
        <div>
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="wasteFractionName"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.Fraction", "Fraction") }}
          </order-table-icon>
        </div>
        <!-- EWC  -->
        <div>
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="ewcCode"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.EWCCode", "Ewc code") }}
          </order-table-icon>
        </div>
        <!-- Category  -->
        <div>
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="wasteCategory"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.WasteCategory", "Waste category") }}
          </order-table-icon>
        </div>
        <!-- Location -->
        <div v-if="isShowingInfocardsFromAllLocations">
          <order-table-icon
            @order-clicked="setOrderMode"
            item-key="locationId"
            :active-order-item="orderBy"
            :order-direction="orderByDesc"
          >
            {{ T("Web.Generic.Infocards.PlacementLocation", "Location") }}
          </order-table-icon>
        </div>

        <!-- Actions -->
        <div class="actions">
          <div class="float-right">
            <infocard-list-filter-dropw-down
              v-model="filters"
              :fractions-options="filters.fractionsOptions"
              :ewc-options="filters.ewcCodesOption"
              :categories-options="filters.categoriesOptions"
            />
            <b-button
              v-if="!orderableMode"
              variant="secondary"
              size="sm"
              @click="openCreateInfocardModal"
              class="ml-1"
              >{{ T("Web.Generic.New", "New") }} <feather-icon icon="PlusIcon"
            /></b-button>
          </div>
        </div>
      </header>

      <div
        class="row"
        :class="{ active: orderList.includes(infocard) }"
        v-for="infocard in orderedInfocardList"
        :key="infocard.id"
        @mouseover="handleInfocardMouseOver(infocard)"
        @mouseleave="handleInfocardMouseLeave(infocard)"
      >
        <!-- Cover col -->
        <div class="cover-col">
          <img
            v-if="infocard.coverPath"
            :src="`${apiBaseUrl()}/images/${infocard.coverPath}`"
          />
          <img v-else src="@/assets/images/container-no-image.jpg" />
          <feather-icon
            class="info-icon"
            icon="InfoIcon"
            size="20"
            @click="openShowInfocardModal(infocard.id)"
          />
        </div>
        <!-- Name col -->
        <div class="name-col">
          <span>
            <span class="font-weight-bold">{{ infocard.name }}</span> <br />
            <span
              ><small>{{T("Web.Generic.Infocards.Ref")}}:{{ infocard.refId }}</small></span
            >
          </span>
        </div>
        <!-- Last ordered col -->
        <div class="lastcollected-col" v-if="orderableMode">
          <small v-if="infocard.type == 'ContainerInfoCard'">
            <template class="display-in-grid"
              >
              {{T("Web.Generic.Infocards.LateOrdered", "Last odered")}}: 
            </template>
            <time-since-display :date-time="infocard.lastOrderDate" /><br />
            <a
              href="#"
              @click.prevent="$emit('infocard-orders-show-clicked', infocard)"
              >{{ T("Web.Generic.Infocards.ShowInfocardOrders", "Show orders") }}</a
            >
          </small>
          <small v-else>--</small>
        </div>
        <!-- Collector col -->
        <div class="collector-col">
          <template v-if="infocard.type == 'ContainerInfoCard'">
            <template v-if="viewMode == 'LIST' && !orderableMode">
              <div style="width: 200px">
                <treeselect
                  v-if="collectorsOptions.length > 0"
                  :options="collectorsOptions"
                  :clearable="false"
                  :value="
                    infocard.collectorId ==
                    '00000000-0000-0000-0000-000000000000'
                      ? null
                      : infocard.collectorId
                  "
                  @select="
                    (collector) =>
                      updateInfocardCollector(collector.id, infocard.id)
                  "
                />
              </div>
            </template>
            <template v-else-if="infocard.collectorName">
              <feather-icon
                icon="TruckIcon"
                class="field-icon display-in-grid"
              /><span>{{ infocard.collectorName }}</span>
            </template>
          </template>
        </div>
        <!-- Type col -->
        <div class="type-col" v-if="!orderableMode">
          <div>
            <b-badge
              class="container-variant-badge"
              :variant="
                infocard.type == 'AccountInfoCard' ? 'secondary' : 'primary'
              "
            >
              {{ T(`Web.Generic.Infocards.Types.${infocard.type.replace('InfoCard', '')}`) }}
            </b-badge>

            <span v-if="infocard.type == 'ContainerInfoCard'">
              <small v-if="infocard.size > 0">
                {{ infocard.size | toCurrency }}
                {{ getContainerSizeUnit(infocard.sizeUnit).text }}
              </small>
            </span>
          </div>
        </div>
        <!-- Fraction col -->
        <div class="fraction-col">
          {{ infocard.wasteFractionName }}
        </div>
        <!-- EWC col -->
        <div class="ewc-col">
          {{ infocard.ewcCode }}
        </div>
        <!-- Category col -->
        <div class="category-col">
          {{
            infocard.wasteCategory
              ? T(`Web.Generic.WasteCategories.${infocard.wasteCategory}`)
              : ""
          }}
        </div>
        <!-- Location col -->
        <div class="location-col" v-if="isShowingInfocardsFromAllLocations">
          <feather-icon icon="MapPinIcon" class="field-icon display-in-grid" />
          <span>{{ getLocationNameFromId(infocard.locationId) }}</span>
        </div>
        <!-- Actions col -->
        <div class="action-col">
          <template
            v-if="orderableMode && infocard.type == 'ContainerInfoCard'"
          >
            <b-form-checkbox
              class="float-right"
              v-if="viewMode == 'LIST'"
              :checked="orderList.includes(infocard)"
              @change="toggleOrderlist(infocard)"
              :disabled="!infocard.orderable"
              v-b-tooltip.hover.top="
                !infocard.orderable
                  ? T('Web.OrderingPage.MissingCollectorWithValidEmail', 'Missing collector with valid email attacthed')
                  : null
              "
            />
            <b-button
              v-else
              :variant="
                orderList.includes(infocard) ? 'primary' : 'flat-secondary'
              "
              class="float-right"
              size="sm"
              :disabled="!infocard.orderable"
              v-b-tooltip.hover.top="
                !infocard.orderable
                  ? T('Web.OrderingPage.MissingCollectorWithValidEmail', 'Missing collector with valid email attacthed')
                  : null
              "
              @click="toggleOrderlist(infocard)"
            >
              <template v-if="orderList.includes(infocard)"
                ><feather-icon icon="CheckIcon"
              /></template>
              <template v-else
                >{{ T("Web.Generic.Select", "Select") }} <feather-icon icon="PlusIcon"
              /></template>
            </b-button>
          </template>
          <b-button
            v-else
            class="float-right"
            variant="flat-secondary"
            size="sm"
            @click="openEditInfocardModal(infocard.id)"
          >
            {{ T("Web.Generic.ShowEdit") }}
          </b-button>
        </div>
      </div>
    </section>
    <div class="no_items" v-if="orderedInfocardList.length == 0">
      {{ T("Web.Generic.Infocards.NoInfocardsToShow", "No infocards to show") }}
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormCheckboxGroup,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import CreateOrUpdateInfocardModal from "../CreateOrUpdateInfocardModal.vue";
import { mapGetters, mapActions } from "vuex";
import ShowInfocardModal from "../ShowInfocardModal.vue";
import InfocardListFilterDropwDown from "./InfocardListFilterDropwDown.vue";
import TimeSinceDisplay from "./TimeSinceDisplay.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import OrderTableIcon from "../OrderTableIcon.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ResourceService from "@/services/base/resource.service";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckboxGroup,
    BFormCheckbox,
    CreateOrUpdateInfocardModal,
    ShowInfocardModal,
    InfocardListFilterDropwDown,
    VBTooltip,
    TimeSinceDisplay,
    ConfirmModal,
    OrderTableIcon,
    Treeselect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    infocards: {
      required: false,
      default: () => [],
    },
    filters: {
      required: false,
      default: {
        type: [],
        fractions: [],
        fractionsOptions: [],
        locationId: null,
        ewcCodes: [],
        ewcCodesOptions: [],
        categories: [],
        categoriesOptions: [],
      },
    },
    orderableMode: {
      required: false,
      default: false,
    },
    orderList: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      orderBy: null,
      orderByDesc: "DESC",
    };
  },
  created() {
    if (!this.orderableMode) {
      this.fetchCollectors();
    }
  },
  computed: {
    ...mapGetters({
      locationScope: "appUser/getCurrentLocationScope",
      locations: "locations/list",
      collectors: "collectors/list",
    }),
    isShowingInfocardsFromAllLocations() {
      return this.locationScope == null;
    },
    collectorsOptions() {
      if (!this.collectors) return [];
      return this.collectors.map((item) => ({
        id: item.id,
        label: item.name,
      }));
    },

    orderItems() {
      return this.orderList;
    },
    viewMode: {
      get() {
        return this.getUserSetting("infocards.list.tableLayout", "GRID");
      },
      async set(value) {
        await this.setUserSettingAsync("infocards.list.tableLayout", value);
      },
    },
    orderedInfocardList() {
      let infocards = [...this.infocards];
      if (this.orderBy != null)
        return infocards.sort((a, b) => {
          if (
            !a[this.orderBy] ||
            a[this.orderBy] == "" ||
            a[this.orderBy] == undefined
          )
            return 2;
          else
            return (
              (this.orderByDesc
                ? a[this.orderBy] > b[this.orderBy]
                : a[this.orderBy] < b[this.orderBy]) *
                2 -
              1
            );
        });
      return infocards;
    },
  },
  methods: {
    ...mapActions({ fetchCollectors: "collectors/fetchList" }),
    async openCreateInfocardModal() {
      await this.$refs.createOrUpdateInfocardModal.openCreate(
        this.locationScope
      );
    },
    openShowInfocardModal(infocardId) {
      this.$refs.showInfocardModal.open(infocardId);
    },
    async openEditInfocardModal(infocardId) {
      await this.$refs.createOrUpdateInfocardModal.openEdit(infocardId);
    },
    handleInfocardMouseOver(infocard) {
      this.$emit("infocard-mouse-over", infocard);
    },
    handleInfocardMouseLeave(infocard) {
      this.$emit("infocard-mouse-leave", infocard);
    },
    async toggleOrderlist(infocard) {
      const existingIndex = this.orderList.indexOf(infocard);
      if (existingIndex > -1) this.orderList.splice(existingIndex, 1);
      else {
        const today = new Date();
        const lastOrderDate = new Date(infocard.lastOrderDate);
        if (
          today.getFullYear() === lastOrderDate.getFullYear() &&
          today.getMonth() === lastOrderDate.getMonth() &&
          today.getDate() === lastOrderDate.getDate() &&
          !(await this.$refs.confirmDateModal.confirm(
            this.T("Web.Generic.Ordering.OrderSameDateConfirmText", "This infocard has already been ordered today. Do you wish to continue?")
          ))
        ) {
          return;
        }
        this.orderList.push(infocard);
      }
    },
    setOrderMode(prop) {
      this.orderBy = prop;
      this.orderByDesc = !this.orderByDesc;
    },
    getLocationNameFromId(id) {
      return this.locations.find((l) => l.id == id)?.name;
    },
    async updateInfocardCollector(collectorId, infocardId) {
      const service = new ResourceService("background-data/infocards");
      await service.post(`${infocardId}/collector`, {
        collectorId: collectorId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect__control {
  border-radius: 0px !important;
}
.no_items {
  text-align: center;
}
.wrap {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;

  .infocard-table {
    width: 100%;
    min-width: 1200px;
    max-height: 100%;
    // overflow: auto;
    position: relative;
    display: table;
    margin-bottom: 30px;
    > * {
      display: table-row;
    }
    header > div,
    .row > div {
      display: table-cell;
    }

    header {
      position: sticky;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 10;
      > div {
        color: #6e6b7b;
        font-weight: bold;
        padding: 0 10px;
        border-bottom: 2px solid #f4f4fb;
        vertical-align: middle;
        height: 40px;

        &.list-mode-selector {
          cursor: pointer;
          text-align: center;
          width: 85px;
          background-color: #f4f4fb;
          &:hover {
            color: #407e3d;
          }
        }

        &.actions {
          width: 220px;
        }

        &.order-able {
          &:hover {
            color: #407e3d !important;
            cursor: pointer;
          }
          &.active {
            color: #407e3d !important;
          }
        }
      }
    }

    .row {
      width: 100%;
      //  transition: width 0.4s;

      &:hover,
      &.active {
        background-color: #f4f4fb;
      }
      > div {
        vertical-align: middle;
        height: 64px;
        border-bottom: 1px solid #f4f4fb;
        padding: 0 10px;
        line-height: 17px;

        &.cover-col {
          padding: 0px;
          position: relative;

          img {
            width: 85px;
            height: 64px;
            object-fit: cover;
          }

          .info-icon {
            background-color: #407e3d;
            border-radius: 50%;
            color: #fff;
            position: absolute;
            bottom: 5px;
            right: 5px;
            cursor: pointer;
            z-index: 9;
          }
        }

        &.actions {
          text-align: right;
        }

        .field-icon {
          margin-right: 5px;
          float: left;
        }
      }
      .type-col {
        .badge {
          max-width: 120px;
        }
        > div {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .display-in-grid {
    display: none;
  }

  &.grid {
    // overflow-y: hidden;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .infocard-table {
      min-width: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        display: flex;
      }

      header {
        width: 100%;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;

          &:not(:first-child, :last-child) {
            display: none;
          }
          &.list-mode-selecto {
            width: 85px;
          }
          &.actions {
            width: 100%;
            justify-content: flex-end;
          }
        }
      }
      .row {
        display: flex;
        flex-direction: column;
        width: 258px;
        height: revert;
        margin: 12px;
        border: 2px solid #f4f4fb;
        border-radius: 4px;
        position: relative;

        @media only screen and (max-width: 648px) {
          width: 100%;
        }

        > div {
          border-bottom: 0px;
          height: auto;
          margin-bottom: 5px;
          &.cover-col {
            height: 180px;
            width: 100%;
            overflow: hidden;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid #f4f4fb;

            img {
              height: 180px;
              width: 100%;
              object-fit: cover;
            }
          }

          &.name-col {
            padding: 5px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #f4f4fb;
            margin-bottom: 5px;
            width: 100%;
            span {
              font-size: 15px;
              &:first-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              }
            }
          }

          &.collector-col {
            order: 3;
          }
          &.location-col {
            order: 2;
          }
          &.lastcollected-col {
            order: 4;
          }
          &.action-col {
            margin-top: auto;
            button {
              width: 100%;
              margin: 5px 0;
              background-color: rgba(130, 134, 139, 0.12);
            }
            order: 5;
          }
          &.type-col {
            order: 1;
            display: flex;
            .container-variant-badge {
              position: absolute;
              top: 10px;
            }
          }
          &.fraction-col {
            display: none;
          }
          &.ewc-col {
            display: none;
          }
          &.category-col {
            display: none;
          }
        }
      }
    }
    .display-in-grid {
      display: block;
    }
  }
}
</style>