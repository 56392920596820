<template>
  <div class="map-wrapper">
    <create-or-update-infocard-modal ref="createOrUpdateInfocardModal" />
    <gmaps-map :options="mapOptions" @click="highlightedInfocard = null">
      <template v-for="infocard in localInfocards">
        <infocard-map-marker
          @click="$emit('infocard-marker-clicked', infocard)"
          :key="`marker_${infocard.id}`"
          :infocard="infocard"
        />
        <infocard-map-infobox
          :visible="highlightedInfocard == infocard"
          :key="`infobox_${infocard.id}`"
          :infocard="infocard"
          @edit-infocard-clicked="openEditInfocardModal"
          :orderable-mode="orderableMode"
          :order-list="orderList"
        />
      </template>
    </gmaps-map>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from "x5-gmaps";
import CreateOrUpdateInfocardModal from "../../CreateOrUpdateInfocardModal.vue";
import InfocardMapInfobox from "./InfocardMapInfobox.vue";
import InfocardMapMarker from "./InfocardMapMarker.vue";
import Vue from "vue";

export default {
  components: {
    gmapsMap,
    gmapsMarker,
    InfocardMapMarker,
    InfocardMapInfobox,
    CreateOrUpdateInfocardModal,
  },
  props: {
    lat: {
      required: true,
    },
    lng: {
      required: true,
    },
    infocards: {
      required: false,
      default: () => [],
    },
    highlightedInfocard: {
      required: false,
      default: null,
    },
    orderableMode:{
      required: false,
      default: false
    },
    orderList:{
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      localInfocards: this.infocards,
    };
  },
  computed: {
    mapOptions() {
      return {
        zoom: this.localZoom,
        center: new google.maps.LatLng((this.localLat) ? this.localLat : 56.0499028, (this.localLng) ? this.localLng :10.7097004),
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: true,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
        ],
      };
    },

    localLat() {
      if (this.highlightedInfocard == null) return this.lat;
      return this.highlightedInfocard.latitude;
    },
    localLng() {
      if (this.highlightedInfocard == null) return this.lng;
      return this.highlightedInfocard.longitude;
    },
    localZoom() {
      if (this.highlightedInfocard == null) return this.lat == null ? 6 : 18;
      return 20;
    },
  },
  methods: {
    async openEditInfocardModal(infocardId) {
      await this.$refs.createOrUpdateInfocardModal.openEdit(infocardId);
    },
  },
  watch: {
    infocards(newValue) {
      // Timing issue fix
      this.localInfocards = [];
      const self = this;
      Vue.nextTick(() => {
        self.localInfocards = newValue;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.map-wrapper {
  width: 100%;
  height: 100%;
}
</style>